import React, { createContext, useState, useMemo, useContext, useCallback } from 'react';
import {
  SdfIssue,
  SdfStepType,
  SuperDuperFiestaDataClass,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
} from '../data/SuperDuperFiestaData';
import { useFilterState } from 'components/hooks/UseFilterState';
import { FilterAction, initialFilterState, FilterState } from 'components/hooks/UseFilterState';

export interface IUpdateValueProps {
  id: string;
  stepUpdateValueId: string | undefined;
  field: string | undefined;
  value: string | undefined;
  removed: boolean;
}

export const defaultUpdateValue = {
  stepUpdateValueId: undefined,
  field: '',
  value: '',
  removed: false,
};

export interface IRuleFormInput {
  executionOrder: number | null;
  ruleName: string;
  description: string;
  queryCondition: string;
  queryJoin: string;
  queryGroupBy: string;
  needsReview: boolean;
  active: boolean;
  stepUpdateValues: IUpdateValueProps[] | null;
  pipeline: SuperDuperFiestaPipeline | null;
  stepType: SdfStepType | null;
  entity: SuperDuperFiestaEntity | null;
  stage: SuperDuperFiestaStage | null;
  dataClass: SuperDuperFiestaDataClass | null;
  dataSources: SuperDuperFiestaDataSource[] | null;
  issue: SdfIssue | null;
}

export const defaultFormValues = {
  executionOrder: null,
  ruleName: '',
  description: '',
  queryCondition: '',
  queryJoin: '',
  queryGroupBy: '',
  needsReview: false,
  active: true,
  stepUpdateValues: [defaultUpdateValue],
  pipeline: null,
  stepType: null,
  entity: null,
  stage: null,
  dataSource: null,
  dataClass: null,
  issue: null,
};

interface IFilteredState {
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
}

export interface IPipelineRulesContextData extends IFilteredState {
  filterState: FilterState;
  refreshData: boolean;
}

export interface IPipelineRulesContext extends IPipelineRulesContextData {
  getFilterData: () => void;
  filtersDispatch: React.Dispatch<FilterAction>;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEntity: React.Dispatch<React.SetStateAction<SuperDuperFiestaEntity | null>>;
  setSelectedPipeline: React.Dispatch<React.SetStateAction<SuperDuperFiestaPipeline | null>>;
  setSelectedStage: React.Dispatch<React.SetStateAction<SuperDuperFiestaStage | null>>;
}

const PipelineRulesContext = createContext<IPipelineRulesContext>({
  getFilterData: () => {
    /* empty */
  },
  filterState: initialFilterState,
  filtersDispatch: () => {
    /* empty */
  },
  refreshData: false,
  setRefreshData: () => {
    /* empty */
  },
  selectedEntity: null,
  setSelectedEntity: () => {
    /* empty */
  },
  selectedPipeline: null,
  setSelectedPipeline: () => {
    /* empty */
  },
  selectedStage: null,
  setSelectedStage: () => {
    /* empty */
  },
});

export const PipelineRulesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { filterState, filtersDispatch, getFilterData } = useFilterState({
    includeEntities: true,
    includeStages: true,
    includeDataClasses: true,
    includeDataSources: true,
    includeIssueTypes: true,
    includePipelines: true,
    includeStepTypes: true,
    includeIssues: true,
  });

  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<SuperDuperFiestaEntity | null>(null);
  const [selectedPipeline, setSelectedPipeline] = useState<SuperDuperFiestaPipeline | null>(null);
  const [selectedStage, setSelectedStage] = useState<SuperDuperFiestaStage | null>(null);

  const getFilterDataMemo = useCallback(() => {
    return getFilterData();
  }, [getFilterData]);

  const contextValue = useMemo(
    () => ({
      getFilterData: getFilterDataMemo,
      filterState,
      filtersDispatch,
      refreshData,
      setRefreshData,
      selectedEntity,
      setSelectedEntity,
      selectedPipeline,
      setSelectedPipeline,
      selectedStage,
      setSelectedStage,
    }),
    [getFilterDataMemo, filterState, filtersDispatch, refreshData, selectedEntity, selectedPipeline, selectedStage]
  );

  return <PipelineRulesContext.Provider value={contextValue}>{children}</PipelineRulesContext.Provider>;
};

export const usePipelineRulesContext = () => {
  const context = useContext(PipelineRulesContext);
  if (!context) {
    throw new Error('usePipelineRulesContext must be used within a PipelineRulesContextProvider');
  }
  return context;
};

export default PipelineRulesContext;
