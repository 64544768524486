import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Tooltip, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CancelButton } from 'components/CancelButton';
import { DeleteButton } from 'components/DeleteButton';
import { FlexBox } from 'components/FlexBox';
import { PrimaryButton } from 'components/PrimaryButton';
import { usePipelineRules } from './hooks/usePipelineRules';
import { PipelineRulesModalContent } from './PipelineRulesModalContent';
import { PipelineRulesModalGrid } from './PipelineRulesModalGrid';
import { DialogCloseButton } from '../../../components/DialogCloseButton';
import { DialogOpenButton } from '../../../components/DialogOpenButton';
import { ErrorIndicator } from '../../../components/ErrorIndicator';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import {
  PipelineRule,
  SuperDuperFiestaStage,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaEntity,
} from '../../../data/SuperDuperFiestaData';

interface PipelineRulesModalProps {
  canOpen: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allRows: PipelineRule[] | null;
  selectedRow?: PipelineRule | null;
  setSelectedRow?: React.Dispatch<React.SetStateAction<PipelineRule | null>>;
  clonedRules?: PipelineRule[];
  setRuleCloneSelection?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  selectedRowIds?: string[];
  setRowSelection?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
}

export const PipelineRulesModal = ({
  canOpen,
  open,
  setOpen,
  allRows,
  selectedRow = null,
  setSelectedRow,
  clonedRules = [],
  setRuleCloneSelection,
  selectedRowIds = [],
  setRowSelection,
  selectedEntity,
  selectedPipeline,
  selectedStage,
}: PipelineRulesModalProps) => {
  const {
    loadingState,
    rows,
    isNew,
    handleOpen,
    handleClose,
    handleAddOrUpdateRule,
    handleSave,
    handleUpdate,
    handleDelete,
    control,
    trigger,
    watch,
    setValue,
    selectedDataSources,
    setSelectedDataSources,
    newStepIds,
    editedStepId,
    hasDuplicateRules,
    removeRow,
    editRow,
    setRows,
    handleSubmit,
    formState: { isDirty, isValid },
    filterState,
    filtersDispatch,
  } = usePipelineRules({
    selectedEntity,
    selectedPipeline,
    selectedStage,
    allRows: allRows ?? [],
    selectedRow,
    setSelectedRow: setSelectedRow || (() => {}),
    clonedRules,
    setRuleCloneSelection: setRuleCloneSelection || (() => {}),
    open,
    setOpen,
    selectedRowIds,
    setRowSelection: setRowSelection || (() => {}),
  });

  return (
    <>
      <Tooltip title={'All required filters must be selected'} disableHoverListener={canOpen}>
        <span>
          <DialogOpenButton title={'Create Rule'} onClick={handleOpen} disabled={!canOpen} />
        </span>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='xl'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          {isNew ? 'Create New Rule(s)' : 'Update Rule'}
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ flexGrow: 1, height: '100vh', mt: 1 }}>
            <PipelineRulesModalContent
              selectedEntity={selectedEntity}
              selectedDataSources={selectedDataSources}
              setSelectedDataSources={setSelectedDataSources}
              control={control}
              trigger={trigger}
              watch={watch}
              setValue={setValue}
              isNew={isNew}
              filterState={filterState}
              filtersDispatch={filtersDispatch}
            />
            <FlexBox flexDirection={'row-reverse'}>
              <DialogOpenButton
                title={isNew ? 'Add Rule' : 'Update Rule'}
                onClick={handleSubmit(handleAddOrUpdateRule)}
                disabled={!isDirty || !isValid}
              />
            </FlexBox>
            <Divider sx={{ mb: 2, background: 'lightgray' }} />
            <PipelineRulesModalGrid
              rows={rows}
              setRows={setRows}
              interactableRows={rows.filter(r =>
                [...newStepIds, editedStepId].filter(id => id !== null).includes(r.stepId)
              )}
              removeRow={removeRow}
              editRow={editRow}
              newStepIds={newStepIds}
            />
          </Box>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        {hasDuplicateRules && (
          <FlexBox sx={{ px: 3, py: 1, color: 'error.main', alignItems: 'center' }}>
            <ErrorOutlineIcon />
            <Typography variant='body2' fontWeight='bold' pl={1}>
              Some rules have the same Name and Data Source. Please review and adjust before saving. Alternatively, you
              can mark the duplicate rule as inactive.
            </Typography>
          </FlexBox>
        )}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={loadingState.status === 'Loading'} />
          <DeleteButton onClick={handleDelete} disabled={loadingState.status === 'Loading'} hidden={isNew} />
          <PrimaryButton
            onClick={isNew ? handleSave : handleUpdate}
            disabled={
              loadingState.status === 'Loading' ||
              (newStepIds.length === 0 && editedStepId === null) ||
              hasDuplicateRules
            }
          >
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
