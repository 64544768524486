import React from 'react';
import { Checkbox, FormControl, FormGroup, Grid, Stack, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { FlexBox } from 'components/FlexBox';
import { FormCheckboxField } from 'components/form/FormCheckboxField';
import { FormSelectField } from 'components/form/FormSelectField';
import { FormTextField } from 'components/form/FormTextField';
import { Control, UseFormSetValue, UseFormTrigger, UseFormWatch, useFieldArray } from 'react-hook-form';
import { AutocompleteWrapper } from '../../../components/AutocompleteWrapper';
import { IRuleFormInput } from '../../../contexts/PipelineRulesContext';
import { SuperDuperFiestaDataSource, SuperDuperFiestaEntity, SdfIssue } from '../../../data/SuperDuperFiestaData';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import { flaggingName } from '../../../util/Constants';
import { CreateDataClassModal } from '../CreateDataClassModal';
import { CreateIssueModal } from '../CreateIssueModal';
import { StepUpdateValueList } from './StepUpdateValueList';
import { StepUpdateValueModal } from './StepUpdateValueModal';
import { FilterState, FilterAction } from 'components/hooks/UseFilterState';

export const PipelineRulesModalContent = ({
  selectedEntity,
  selectedDataSources,
  setSelectedDataSources,
  control,
  trigger,
  watch,
  setValue,
  isNew,
  filterState,
  filtersDispatch,
}: {
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedDataSources: SuperDuperFiestaDataSource[] | [];
  setSelectedDataSources: React.Dispatch<React.SetStateAction<SuperDuperFiestaDataSource[] | []>>;
  control: Control<IRuleFormInput, any>;
  trigger: UseFormTrigger<IRuleFormInput>;
  watch: UseFormWatch<IRuleFormInput>;
  setValue: UseFormSetValue<IRuleFormInput>;
  isNew: boolean;
  filterState: FilterState;
  filtersDispatch: React.Dispatch<FilterAction>;
}) => {
  const { t } = useMemoTranslation();
  const { fields, append, remove, update } = useFieldArray({ control, name: 'stepUpdateValues' });
  const watchStepType = watch('stepType');
  const filteredStages = ['promoteToAugmentation', 'promoteToSelection'];

  const handleIssueCreate = (issue: SdfIssue) => {
    filtersDispatch({
      type: 'update_issues',
      issues: [issue, ...filterState.issues],
    });
    setValue('issue', issue);
    trigger('issue');
  };

  return (
    <Grid container spacing={2} columns={16}>
      <Grid item xs={4}>
        <FormSelectField
          name='pipeline'
          control={control}
          label={t('pipeline')}
          trigger={trigger}
          options={filterState.pipelines}
          required={true}
          sx={{ width: '90%' }}
          inputSx={{ mt: 0 }}
          disabled={!!watch('pipeline')}
        />
        <FormSelectField
          name='stage'
          control={control}
          label={t('stage')}
          trigger={trigger}
          options={filterState.stages.filter(s => !filteredStages.includes(s.name))}
          required={true}
          optionLabel='displayName'
          sx={{ width: '90%' }}
          disabled={true}
        />
        <FormSelectField
          name='entity'
          control={control}
          label={t('entity')}
          trigger={trigger}
          options={filterState.entities}
          required={true}
          optionLabel='displayName'
          sx={{ width: '90%' }}
          disabled={true}
        />
        <FormSelectField
          name='stepType'
          control={control}
          label={'Type'}
          trigger={trigger}
          options={filterState.stepTypes}
          required={true}
          optionLabel='displayName'
          sx={{ width: '90%' }}
        />
        <FlexBox>
          <FormSelectField
            name='issue'
            control={control}
            label={'Issue'}
            trigger={trigger}
            options={filterState.issues}
            required={watchStepType?.name === flaggingName}
            disabled={watchStepType === null || watchStepType === undefined}
          />
          <CreateIssueModal onSave={handleIssueCreate} issueTypes={filterState.issueTypes} />
        </FlexBox>
        <FlexBox>
          <FormSelectField
            name='dataClass'
            control={control}
            label={'Data Class'}
            trigger={trigger}
            options={filterState.dataClasses.filter(s => s.entityId === selectedEntity?.entityId)}
          />
          <CreateDataClassModal entity={selectedEntity} entities={filterState.entities} setValue={setValue} />
        </FlexBox>
        <FormTextField
          name='ruleName'
          control={control}
          label='Name'
          trigger={trigger}
          required={true}
          sx={{ mb: '4px', mt: '8px' }}
        />
        <FormTextField
          name='description'
          control={control}
          label='Description'
          trigger={trigger}
          sx={{ mb: '4px', mt: '8px' }}
          additionalProps={{ multiline: true, rows: 2 }}
        />
      </Grid>
      <Grid item xs={7}>
        <Stack spacing={2}>
          <FormTextField
            name='queryCondition'
            control={control}
            label='Query Condition'
            trigger={trigger}
            additionalProps={{ multiline: true, rows: 6 }}
          />
          <FormTextField
            name='queryJoin'
            control={control}
            label='Query Join'
            trigger={trigger}
            additionalProps={{ multiline: true, rows: 4 }}
          />
          <FormTextField name='queryGroupBy' control={control} label='Query Group By' trigger={trigger} />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
          <FormControl component='fieldset' variant='standard'>
            <FormGroup>
              <FormCheckboxField
                name='needsReview'
                control={control}
                label='Needs Review'
                trigger={trigger}
                sx={{ justifyContent: 'space-between', marginLeft: 0 }}
              />
              <FormCheckboxField
                name='active'
                control={control}
                label='Active'
                trigger={trigger}
                sx={{ justifyContent: 'space-between', marginLeft: 0 }}
              />
            </FormGroup>
          </FormControl>
          <AutocompleteWrapper
            fullWidth
            multiple
            size='small'
            value={selectedDataSources}
            options={filterState.dataSources}
            getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
            renderInput={params => <TextField {...params} label={'Data Source'} margin='dense' />}
            filterOptions={(options, params) => {
              const filter = createFilterOptions<SuperDuperFiestaDataSource>();
              const filtered = filter(options, params);
              if (isNew) {
                return [{ dataSourceId: '', name: '', displayName: 'Select All' }, ...filtered];
              } else {
                return [...filtered];
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ marginRight: 8 }}
                  checked={
                    option.displayName === 'Select All'
                      ? !!(filterState.dataSources.length === selectedDataSources.length)
                      : selected
                  }
                />
                {option.displayName}
              </li>
            )}
            onChange={(_, values) => {
              // Not actually using this value. Just used to make dirty.
              setValue('dataSources', values, { shouldDirty: true });
              if (values.find(option => option.displayName === 'Select All')) {
                return setSelectedDataSources(
                  filterState.dataSources.length === selectedDataSources.length ? [] : filterState.dataSources
                );
              }
              if (!isNew) {
                return setSelectedDataSources(
                  [values.at(-1)].filter(v => v !== undefined) as SuperDuperFiestaDataSource[]
                );
              }
              setSelectedDataSources(values);
            }}
          />
          <FormTextField
            name='executionOrder'
            control={control}
            label='Execution Order'
            trigger={trigger}
            additionalProps={{ type: 'number' }}
          />
          <StepUpdateValueList
            maxIndex={3}
            control={control}
            trigger={trigger}
            fields={fields}
            append={append}
            remove={remove}
            update={update}
            disabled={watchStepType?.name === flaggingName}
          />
          <StepUpdateValueModal
            control={control}
            trigger={trigger}
            fields={fields}
            append={append}
            remove={remove}
            update={update}
            disabled={watchStepType?.name === flaggingName}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
