import { useCallback, useMemo, useReducer } from 'react';
import useAuth from 'auth/UseAuth';
import {
  GetPipelineFilters,
  PipelineFilters,
  SdfIssueType,
  SuperDuperFiestaDataClass,
  SuperDuperFiestaDataSource,
  SuperDuperFiestaEntity,
  SuperDuperFiestaStage,
  SdfIssue,
  SdfStepType,
  SuperDuperFiestaPipeline,
} from '../../data/SuperDuperFiestaData';

export interface FilterState {
  dataClasses: SuperDuperFiestaDataClass[];
  dataSources: SuperDuperFiestaDataSource[];
  entities: SuperDuperFiestaEntity[];
  stages: SuperDuperFiestaStage[];
  issueTypes: SdfIssueType[];
  issues: SdfIssue[];
  pipelines: SuperDuperFiestaPipeline[];
  stepTypes: SdfStepType[];
}

export type FilterAction = {
  type:
    | 'update_stages'
    | 'update_entities'
    | 'update_dataClasses'
    | 'update_dataSources'
    | 'update_issueTypes'
    | 'update_pipelines'
    | 'update_stepTypes'
    | 'update_issues'
    | 'update_all'
    | 'reset';
} & Partial<FilterState>;

export const initialFilterState: FilterState = {
  stages: [],
  entities: [],
  dataClasses: [],
  dataSources: [],
  issueTypes: [],
  issues: [],
  pipelines: [],
  stepTypes: [],
};

export interface FilterOptions {
  includeEntities?: boolean;
  includeStages?: boolean;
  includeDataClasses?: boolean;
  includeDataSources?: boolean;
  includeIssueTypes?: boolean;
  includePipelines?: boolean;
  includeStepTypes?: boolean;
  includeIssues?: boolean;
}

function createFilterReducer(initialState: FilterState) {
  return (state: FilterState, action: FilterAction): FilterState => {
    const { type } = action;

    switch (type) {
      case 'update_stages':
        return { ...state, stages: action.stages ?? [] };
      case 'update_entities':
        return { ...state, entities: action.entities ?? [] };
      case 'update_dataClasses':
        return { ...state, dataClasses: action.dataClasses ?? [] };
      case 'update_dataSources':
        return { ...state, dataSources: action.dataSources ?? [] };
      case 'update_issueTypes':
        return { ...state, issueTypes: action.issueTypes ?? [] };
      case 'update_pipelines':
        return { ...state, pipelines: action.pipelines ?? [] };
      case 'update_stepTypes':
        return { ...state, stepTypes: action.stepTypes ?? [] };
      case 'update_issues':
        return { ...state, issues: action.issues ?? [] };
      case 'update_all':
        return { ...state, ...action };
      case 'reset':
        return { ...initialState };
      default:
        return state;
    }
  };
}

/**
 * A hook for managing filter state in the Super Duper Fiesta pipeline interface.
 *
 * @param options - Configuration object to specify which filter types should be included
 * @param initialState - Initial filter state (defaults to empty arrays for all filter types)
 * @returns {
 *   filterState: Current state of all filters
 *   filtersDispatch: Function to update filter state
 *   getFilterData: Function to fetch filter data from the API
 * }
 */
export function useFilterState(options: FilterOptions = {}, initialState: FilterState = initialFilterState) {
  const { accessToken } = useAuth();
  const [filterState, dispatch] = useReducer(createFilterReducer(initialState), initialState);

  const queryParams = useMemo(() => {
    return {
      includeEntities: (options.includeEntities ?? false) && filterState.entities.length === 0,
      includeStages: (options.includeStages ?? false) && filterState.stages.length === 0,
      includeDataClasses: (options.includeDataClasses ?? false) && filterState.dataClasses.length === 0,
      includeDataSources: (options.includeDataSources ?? false) && filterState.dataSources.length === 0,
      includeIssueTypes: (options.includeIssueTypes ?? false) && filterState.issueTypes.length === 0,
      includePipelines: (options.includePipelines ?? false) && filterState.pipelines?.length === 0,
      includeStepTypes: (options.includeStepTypes ?? false) && filterState.stepTypes?.length === 0,
      includeIssues: (options.includeIssues ?? false) && filterState.issues?.length === 0,
    };
  }, [filterState, options]);

  const initialStateKeys = useMemo(() => Object.keys(initialState), [initialState]);

  const fetchAndProcessFilters = useCallback(async () => {
    if (!accessToken) {
      return;
    }

    if (Object.values(queryParams).every(value => !value)) {
      return;
    }

    const data: PipelineFilters = await GetPipelineFilters(queryParams, accessToken);

    if (data) {
      const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => initialStateKeys.includes(key)));
      dispatch({ type: 'update_all', ...(filteredData as Partial<FilterState>) });
    }
  }, [accessToken, queryParams, initialStateKeys]);

  const getFilterData = useCallback(() => {
    return fetchAndProcessFilters();
  }, [fetchAndProcessFilters]);

  return { filterState, filtersDispatch: dispatch, getFilterData };
}
